<template>
	<div style="height:100%;width:100%">
		<DxBox height="40px" direction="row" width="100%">
			<DxItem :ratio="0" :base-size="150">
				<template #default>
					<div>
						<DxDropDownButton
							:items="themeItems"
							:text="cmOptions.theme"
							@selection-changed="cmOptions.theme = $event.item"
							styling-mode="outlined"
						/>
					</div>
				</template>
			</DxItem>
			<DxItem :ratio="1">
				<template #default>
					<div>
						&nbsp;
					</div>
				</template>
			</DxItem>
			<DxItem :ratio="0" align="right" :base-size="130">
				<template #default>
					<div>
						<DxButton
							text="소스 정렬"
							type="normal"
							styling-mode="outlined"
							@click="codeBeautify"
							class="btn_XS default filled txt_S medium"
						/>
					</div>
				</template>
			</DxItem>
			<DxItem :ratio="0" align="right" :base-size="130">
				<template #default>
					<div>
						<DxButton
							text="소스 복사"
							type="normal"
							styling-mode="outlined"
							@click="copyClipboard"
							class="btn_XS default filled txt_S medium"
						/>
					</div>
				</template>
			</DxItem>
			<DxItem :ratio="0" align="right" :base-size="90">
				<template #default>
					<div>
						<DxButton
							text="검증"
							type="normal"
							styling-mode="outlined"
							@click="validateJsonEditor"
							class="btn_XS white light_filled mr-10"
						/>
					</div>
				</template>
			</DxItem>
		</DxBox>
		<DxBox height="100%" direction="row" width="100%">
			<DxItem :ratio="1">
				<template #default>
					<div>
						<dx-scroll-view id="scrollview" ref="scrollViewWidget">
							<codemirror
								ref="cmEditor"
								v-model="code"
								:options="cmOptions"
								@ready="onCmReady"
								@focus="onCmFocus"
								@input="onCmCodeChange"
								@keydown.tab.prevent="onTabKeydown"
							/>
						</dx-scroll-view>
					</div>
				</template>
			</DxItem>
		</DxBox>
	</div>
</template>

<script>
let beautify = require('js-beautify').js;
var beautify_html = require('js-beautify').html;
import { codemirror } from 'vue-codemirror';
import { DxBox, DxItem } from 'devextreme-vue/box';
import DxButton from 'devextreme-vue/button';
import { DxScrollView } from 'devextreme-vue/scroll-view';

// import base style
import 'codemirror/lib/codemirror.css';
// import language js
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/vue/vue.js';

// import theme style
import 'codemirror/theme/monokai.css';
import 'codemirror/theme/base16-dark.css';
import 'codemirror/theme/paraiso-light.css';
import 'codemirror/theme/3024-day.css';
import 'codemirror/theme/3024-night.css';

// require active-line.js
import 'codemirror/addon/selection/active-line.js';

// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js';

// hint
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/javascript-hint.js';
import 'codemirror/addon/hint/xml-hint.js';
import 'codemirror/addon/edit/closetag.js';

// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js';
import 'codemirror/addon/search/matchesonscrollbar.js';
import 'codemirror/addon/search/searchcursor.js';
import 'codemirror/addon/search/match-highlighter.js';

// keyMap
import 'codemirror/mode/clike/clike.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/comment/comment.js';
import 'codemirror/addon/dialog/dialog.js';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/search/search.js';
import 'codemirror/keymap/sublime.js';

// foldGutter
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/comment-fold.js';
import 'codemirror/addon/fold/foldcode.js';
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/indent-fold.js';
import 'codemirror/addon/fold/markdown-fold.js';
import 'codemirror/addon/fold/xml-fold.js';

import DxDropDownButton from 'devextreme-vue/drop-down-button';

export default {
	components: {
		codemirror,
		DxBox,
		DxItem,
		DxButton,
		DxDropDownButton,
		DxScrollView,
	},
	props: {
		beautyType: String,
		value: String,
	},
	watch: {
		value: {
			//data with key and list to send to parent component
			handler(val) {
				let filterData = val;
				if (filterData) {
					this.code = `${filterData}`;
				}
			},
			
			deep: true,
			immediate: true,
		},
	},
	data() {
		return {
			scrollview: {
				showScrollbar: 'onScroll',
				scrollByContent: true,
				scrollByThumb: true,
				pullDown: false,
			},
			code: '',
			cmOptions: {
				tabSize: 4,
				styleActiveLine: false,
				styleSelectedText: false,
				line: true,
				lineNumbers: true,
				foldGutter: true,
				gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
				highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
				mode: 'text/javascript',
				// hint.js options
				hintOptions: {
					completeSingle: false,
				},
				keyMap: 'sublime',
				matchBrackets: true,
				showCursorWhenSelecting: true,
				theme: 'default',
				extraKeys: { Ctrl: 'autocomplete' },
				lineWrapping: true,
				autoCloseTags: true,
			},
			themeItems: ['default', 'monokai', 'paraiso-light', 'base16-dark', '3024-day', '3024-night'],
			beautyfyOption: {
				indent_size: 4,
				indent_char: ' ',
				max_preserve_newlines: '4',
				preserve_newlines: false,
				keep_array_indentation: true,
				break_chained_methods: false,
				indent_scripts: 'normal',
				brace_style: 'collapse',
				space_before_conditional: true,
				unescape_strings: false,
				jslint_happy: false,
				end_with_newline: false,
				wrap_line_length: '0',
				indent_inner_html: true,
				comma_first: false,
				e4x: false,
				indent_empty_lines: false,
			},
		};
	},
	methods: {
		codeBeautify() {
			if (this.beautyType === 'js') {
				this.code = beautify(this.code, this.beautyfyOption);
			} else if (this.beautyType === 'html') {
				this.code = beautify_html(this.code, this.beautyfyOption);
			}
		},
		copyClipboard() {
      this.$_copyToClipboard(this.code);
		},
		selectAll() {},
		onCmReady(cm) {
			console.log('the editor is readied!', cm);
		},
		onCmFocus(cm) {
			console.log('the editor is focused!', cm);
		},
		onCmCodeChange(newCode) {
			this.code = newCode;
			this.$emit('input', this.code);
		},
		onTabKeydown(event) {
			if (event.keyCode === 9) {
				event.preventDefault();
				const start = this.$refs.cmEditor.getCursor('start');
				const end = this.$refs.cmEditor.getCursor('end');
				const spaces = Array(this.options.tabSize + 1).join(' ');
				this.code = this.code.slice(0, start.ch) + spaces + this.code.slice(end.ch);
				this.$refs.cmEditor.setCursor(start.line, start.ch + this.options.tabSize);
			}
		},
		setMode(mode) {
			if (mode) {
				this.cmOptions.mode = mode;
			}
		},
		async validateJsonEditor() {
			const pageData = this.code;

			try {
				var jsonStr = JSON.parse(pageData);
				this.$_Msg('검증 완료되었습니다.');
				return (typeof jsonStr === 'object');
			} catch (e) {
				this.$_Msg('검증 실패하였습니다.');
				return false;
			}
        },
		// setCode(data){
		//   //this.code = data;
		//   //코드미러 데이터 로드시 이스케이핑 되어 다시 언이스케이핑 처리(&gt; &amp; -> > &)
		//   let textareaTag = document.createElement('textarea');
		//   textareaTag.innerHTML = data;
		//   this.code = textareaTag.textContent;
		// },
	},
	computed: {
		codemirror() {
			return this.$refs.cmEditor.codemirror;
		},
	},
	created() {
		this.setMode(this.mode);
	},
	mounted() {
		//console.log('the current CodeMirror instance object:', this.codemirror)
		//$('.vue-codemirror').css({'position':'relative', 'height':'95%'});
		//$('.CodeMirror').css('height', '90%');
		// you can use this.codemirror to do something...
	},
};
</script>

<style scoped>
.vue-codemirror {
	height: 94%;
	position: relative;
}

#scrollview {
	height: auto;
	padding-bottom: 80px;
}
</style>

<style>
.CodeMirror {
	border: 1px solid #eee;
	height: auto;
}

.CodeMirror-code {
	min-height: 600px;
}

.CodeMirror-focused .cm-matchhighlight {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFklEQVQI12NgYGBgkKzc8x9CMDAwAAAmhwSbidEoSQAAAABJRU5ErkJggg==);
	background-position: bottom;
	background-repeat: repeat-x;
}

.cm-matchhighlight {
	background-color: lightgreen;
}

.CodeMirror-selection-highlight-scrollbar {
	background-color: green;
}
</style>
