import { render, staticRenderFns } from "./codemirror-json-editor.vue?vue&type=template&id=1886d07a&scoped=true"
import script from "./codemirror-json-editor.vue?vue&type=script&lang=js"
export * from "./codemirror-json-editor.vue?vue&type=script&lang=js"
import style0 from "./codemirror-json-editor.vue?vue&type=style&index=0&id=1886d07a&prod&scoped=true&lang=css"
import style1 from "./codemirror-json-editor.vue?vue&type=style&index=1&id=1886d07a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1886d07a",
  null
  
)

export default component.exports