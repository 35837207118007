<!--
  PACKAGE_NAME : src\pages\setting\system\menu\modal-menu-page-data.vue
  FILE_NAME : modal-menu-page-data
  AUTHOR : supark
  DATE : 2021-07-28
  DESCRIPTION : pageData 설정 팝업 컴포넌트
-->
<template>
  <div style="height: 100%; width: 100%">
    <transition>
      <div class="flex-modal-container">
        <!-- [START] 좌측 영역 -->
        <div class="flex-modal-item">
          <!-- 헤더 -->
          <div class="flex-between">
            <h2 class="section-title">
              <span class="text-lg font-bold">{{ 'Page data' }}</span>
              <span class="text-sm" v-if="options.menuId">({{ cmEditor.menuNm }})</span>
            </h2>
          </div>

          <!-- 에디터 -->
          <div class="editor-style">
            <codemirror-editor v-model="pageData" beautyType="js" />
          </div>
        </div>
        <!-- [END] 좌측 영역 -->

        <!-- [START] 히스토리 및 설명 영역 -->
        <div v-if="options.useRight" class="flex-modal-item" :style="{ width: options.rsWidth }">
          <h2 class="section-title">작업 이력</h2>
          <table class="table_form line-bin th-bold">
            <tbody>
              <tr>
                <td>
                  <DxDataGrid
                    class="grid-box"
                    ref="workHistory"
                    :data-source="copyHistory"
                    :show-borders="true"
                    :show-column-headers="true"
                    :show-column-lines="false"
                    :show-row-lines="true"
                    height="calc(100vh - 460px)"
                    :row-alternation-enabled="false"
                    :allow-column-reordering="false"
                    no-data-text="데이터가 존재하지 않습니다."
                    :visible="true"
                    key-expr="id"
                    :hover-state-enabled="true"
                    :selected-row-keys="selectedRowKey"
                    @cell-hover-changed="onCellHoverChanged"
                    @content-ready="selectFirstRow"
                    @selection-changed="handlerSelectionRow"
                  >
                    <DxPager :visible="false" />
                    <DxSelection mode="single" />
                    <DxColumnFixing :enabled="false" />
                    <DxColumn
                      caption="작업자"
                      data-field="editId"
                      :fixed="true"
                      alignment="left"
                      :visible="true"
                      :allow-sorting="false"
                    />
                    <DxColumn
                      caption="작업일시"
                      data-field="editDt"
                      alignment="center"
                      :fixed="true"
                      :visible="true"
                      :allow-sorting="false"
                      :calculateCellValue="this.formatEditDt"
                    />
                    <DxScrolling row-rendering-mode="virtual" />
                  </DxDataGrid>
                </td>
              </tr>
              <tr>
                <td>
                  <DxTextArea
                    ref="description"
                    width="100%"
                    :height="100"
                    :max-length="200"
                    v-model="description"
                    class="mar_ri10 alB"
                    styling-mode="outlined"
                    @key-up="keyupDescription($event)"
                  />
                  <div class="mt-05 text-end">{{ descriptionLength }}/100 자</div>
                </td>
              </tr>
            </tbody>
          </table>
          <DxPopover
            width="auto"
            position="bottom"
            :target="popover.target"
            :visible="popover.visible"
            :close-on-outside-click="true"
          >
            <p>{{ popover.content }}</p>
          </DxPopover>
        </div>
        <!-- [END] 히스토리 및 설명 영역 -->
      </div>
    </transition>
  </div>
</template>

<script>
  import CodemirrorEditor from '@/components/codemirror/codemirror-json-editor.vue';
  import { DxPopover } from 'devextreme-vue/popover';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import { DxColumn, DxColumnFixing, DxDataGrid, DxPager, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';

  export default {
    components: {
      DxPopover,
      DxDataGrid,
      DxTextArea,
      DxPager,
      DxColumn,
      DxScrolling,
      DxColumnFixing,
      DxSelection,
      CodemirrorEditor,
    },
    props: {
      contentData: Object,
      menuId: {
        type: Number,
        default: 0,
      },
      options: {
        type: Object,
        default: () => {
          return {
            type: 'REPORT', // type: ['REPORT', 'MASTER']
            menuNm: '',
            menuId: undefined,
            useRight: false,
            useHeader: false,
            useCreateCols: false,
          };
        },
      },
      history: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      pageData: {
        handler(val) {
          this.cmEditor.pageData = val;
        },
      },
      description: {
        handler(val) {
          this.cmEditor.description = val;
        },
      },
      history: {
        handler() {
          this.createdData();
          this.copyHistory = this.history;
        },
      },
      cmEditor: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },
    data() {
      return {
        copyHistory: [],
        limitLength: {
          menuNm: 50,
        },
        listData: [],
        messageData: null,
        isInit: true,
        cmEditor: {
          menuNm: null,
          pageData: null,
          menuIcon: null,
          description: null,
        },
        pageData: null,
        description: null,
        selectedHistoryId: null,
        selectedRowKey: null,
        popover: {
          target: null,
          content: '',
          visible: false,
        },
      };
    },
    computed: {
      descriptionLength() {
        if ([undefined, null].includes(this.description)) return 0;
        return this.description.length;
      },
    },
    methods: {
      createdData() {
        this.formData = { ...this.formData, ...this.contentData };
        this.cmEditor.id = this.contentData.id;
        this.cmEditor.menuNm = this.contentData.menuNm;
        this.description = this.contentData.description;
        this.pageData = this.contentData.pageData;
      },
      formatEditDt(rowData) {
        const editDt = rowData.editDt;
        if (editDt) {
          return this.$_commonlib.formatDate(editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
        return '-';
      },
      getConfigData(data) {
        let result = '';

        if (typeof data !== 'undefined') {
          result = data.menuConfigContent;
        } else {
          result = '';
        }

        return result;
      },
      setHistoryId(id) {
        this.selectedHistoryId = id;
      },
      keyupDescription(e) {
        this.description = e.event.currentTarget.value;
      },
      setDescription(description) {
        this.description = description;
      },
      async handlerSelectionRow(data) {
        const vm = this;
        let currentDeselectedRowKeys = data.currentDeselectedRowKeys;
        let currentSelectedRowKeys = data.currentSelectedRowKeys;
        let selectedRowsData = data.selectedRowsData;

        const ConfigData = this.getConfigData(selectedRowsData[0]);

        if (history.length > 0 && ConfigData) {
          const { id, description } = selectedRowsData[0];
          this.setHistoryId(id);
          this.setDescription(description);

          // Code Mirror UI 깨짐 방지위해 setTimeout 사용
          if (this.isInit) {
            this.isInit = false;
            setTimeout(() => (vm.pageData = ConfigData), 250);
          } else {
            vm.pageData = ConfigData;
          }
        }

        if (!currentDeselectedRowKeys.length + currentSelectedRowKeys.length === 1) {
          if (!(await vm.$_Confirm('해당 작업 이력의 데이터를 불러오시겠습니까?'))) {
            vm.$refs.workHistory.instance.deselectRows(currentSelectedRowKeys);
            vm.$refs.workHistory.instance.selectRows(currentDeselectedRowKeys);
          }
        }
      },
      selectFirstRow(e) {
        const rowKey = e.component.getKeyByRowIndex(0);
        this.selectedRowKey = [rowKey];
      },
      onCellHoverChanged(e) {
        this.popover.visible = false;
        if (e.rowType === 'data' && e.eventType === 'mouseover') {
          const rowData = this.history.find(v => v.id === e.data.id);
          if (rowData && rowData.description) {
            this.popover.target = e.cellElement;
            this.popover.content = rowData.description;
            this.popover.visible = true;
          }
        }
      },
      async clearMenuConfigData() {
        const vm = this;
        vm.pageData = null;
        vm.description = null;
        vm.selectedRowKey = null;
        vm.formData = {};
        vm.pageData = null;
        vm.copyHistory = null;
      },
    },
  };
</script>
<style scoped>
  .mt-05 {
    margin-top: 5px;
  }

  .flex-modal-container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .flex-modal-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  .flex-modal-item:nth-child(1) {
    flex-grow: 1;
  }

  .flex-modal-item:nth-child(2) {
    flex-shrink: 0;
  }
  .editor-style {
    width: 100%;
    height: 90%;
  }

  .section-title {
    font-size: 18px;
    color: #545454;
    font-weight: 500;
    padding-bottom: 16px;
  }

  .th-bold > tbody > tr > th {
    font-weight: 400;
  }

  .text-end {
    text-align: end;
  }
</style>
